var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"example-headImg",attrs:{"src":require("../../assets/image/selected/03-2-1banner.png")}}),_vm._m(0),_c('div',{staticClass:"head-list"},[_c('div',{staticClass:"head-list-item head-list-item-ischeck",staticStyle:{"margin-left":"26.3rem"},on:{"click":function($event){return _vm.goAssignBlock('block'+ 0,50)}}},[_c('span',{staticClass:"title"},[_vm._v("业务痛点")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 1,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施方案")])]),_c('div',{staticClass:"head-list-item",on:{"click":function($event){return _vm.goAssignBlock('block'+ 2,50)}}},[_c('span',{staticClass:"title"},[_vm._v("实施收益")])])]),_c('div',{ref:"block0",staticClass:"container-1",staticStyle:{"display":"flex"}},[_vm._m(1),_vm._m(2)]),_c('div',{ref:"block1",staticClass:"container-2",staticStyle:{"display":"flex","height":"25rem"}},[_vm._m(3),_vm._m(4)]),_c('div',{ref:"block2",staticClass:"container-1",staticStyle:{"display":"flex","margin-bottom":"10rem"}},[_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head-explainBox"},[_c('div',{staticClass:"head-explainBox-title1"},[_vm._v("5GF整合数据分析系统")]),_c('div',{staticClass:"head-explainBox-content1"},[_vm._v("全球某知名日用消费品公司")]),_c('div',{staticClass:"head-explainBox-line"}),_c('div',{staticClass:"head-explainBox-title2"},[_vm._v("项目介绍")]),_c('div',{staticClass:"head-explainBox-content2"},[_vm._v("作为世界上最大的日用消费品公司之一，客户公司覆盖14个品类的400个品牌，畅销于全球170多个国家和地 区。在各组织内对于各类报表处在大量的需求，可是当时财务部门仍然存在大量纸质或者Excel报表，以至于需要人工录入及核查，影响了报表的时效 性和准确性。所以靠人工对企业经营数据进行统计分析，已经无法满足公司的精细化管理要求。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("业务痛点")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"selected-box"},[_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v(" 各部门有大量纸质、Excel报表需求，存在大量"),_c('br'),_vm._v(" 人工抄写、录入及计算，时效性和准确性较差 ")])]),_c('div',{staticClass:"selected-card"},[_c('div',{staticClass:"title1"},[_vm._v(" 靠人工对企业经营数据进行统计分析，"),_c('br'),_vm._v("已经无法满足公司的精细化管理要求")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-box"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/selected/03-2-1更好的体验.svg")}}),_c('div',[_c('div',{staticClass:"title2"},[_vm._v("使最终用户拥有更好的体验 ")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/selected/03-2-15GF报告.svg")}}),_c('div',[_c('div',{staticClass:"title2"},[_vm._v("建立自动流程以生成5GF报告数据")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/selected/03-2-1数据分析.svg")}}),_c('div',[_c('div',{staticClass:"title2"},[_vm._v("节省分析师的时间，使其专注于数据分析")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-left"},[_c('div',{staticClass:"purchase-title"},[_vm._v("实施收益")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-content-right"},[_c('div',{staticClass:"purchase-box"},[_c('div',{staticClass:"card2"},[_c('img',{staticClass:"logo2",attrs:{"src":require("../../assets/image/selected/03-2-1数据源.svg")}}),_c('div',{staticClass:"contain2"},[_c('div',{staticStyle:{"display":"flex","margin":"auto"}},[_c('div',{staticClass:"title2-1"},[_vm._v("30")]),_c('div',{staticClass:"title2-2"},[_vm._v("个数据源")])])])]),_c('div',{staticClass:"card2"},[_c('img',{staticClass:"logo2",attrs:{"src":require("../../assets/image/selected/03-2-1KPI.svg")}}),_c('div',{staticClass:"contain2"},[_c('div',{staticStyle:{"display":"flex","margin":"auto"}},[_c('div',{staticClass:"title2-1"},[_vm._v("20+")]),_c('div',{staticClass:"title2-2"},[_vm._v("核心KPI")])])])]),_c('div',{staticClass:"card2"},[_c('img',{staticClass:"logo2",attrs:{"src":require("../../assets/image/selected/03-2-1人工成本.svg")}}),_c('div',{staticClass:"contain2"},[_c('div',{staticStyle:{"display":"flex","margin":"auto"}},[_c('div',{staticClass:"title2-2"},[_vm._v("高度节约人工成本")])])])]),_c('div',{staticClass:"card2"},[_c('img',{staticClass:"logo2",attrs:{"src":require("../../assets/image/selected/03-2-1缩短报表时间.svg")}}),_c('div',{staticClass:"contain2"},[_c('div',{staticStyle:{"display":"flex","margin":"auto"}},[_c('div',{staticClass:"title2-2"},[_vm._v("缩短报表制作时间")])])])]),_c('div',{staticClass:"card2"},[_c('img',{staticClass:"logo2",attrs:{"src":require("../../assets/image/selected/03-2-1自动处理.svg")}}),_c('div',{staticClass:"contain2"},[_c('div',{staticStyle:{"display":"flex","margin":"auto"}},[_c('div',{staticClass:"title2-2"},[_vm._v("自动化处理数据")])])])])])])
}]

export { render, staticRenderFns }